import { ListItemText } from '@mui/material';
import React, { useState } from 'react';

interface Source {
  text: string;
  link: string;
  header: string;
}

interface Props {
  sources: Source[];
}

const SourcesView = (props: Props) => {
  const [selectedSource, setSelectedSource] = useState<Source | null>(null);

  const handleSourceClick = (source: Source) => {
    if (selectedSource === source) {
      setSelectedSource(null);
    } else {
      setSelectedSource(source);
    }
  };

  return (
    <div>
        
        <ListItemText primary= { props.sources.map((source, index) => (
          <>
            <a href="#" key={index} onClick={() => handleSourceClick(source)}>
              {index+1}  
            </a> &nbsp;
        </>
          
        ))}>
        </ListItemText>
      
      {selectedSource && (
        <div>
         <ListItemText primary= {<a href={selectedSource.link} target="_blank" >Източник </a>}>
            
         </ListItemText>
         <ListItemText primary= {selectedSource.header}>  </ListItemText>
         <ListItemText primary= {selectedSource.text}>  </ListItemText>
        </div>
      )}
    </div>
  );
};

export default SourcesView;