import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  TextField,
  Fab,
  IconButton,
  CircularProgress,
  Avatar,
  Box,
} from "@material-ui/core";
import {
  Send as SendIcon,
  ThumbUp as ThumbUpIcon,
  ThumbDown as ThumbDownIcon,
  Help as HelpIcon,
} from "@material-ui/icons";

import SourcesView from "./SourcesComponent";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: "100%",
    height: "80vh",
  },
  headBG: {
    backgroundColor: "#e0e0e0",
  },
  borderRight500: {
    borderRight: "1px solid #e0e0e0",
  },
  messageArea: {
    height: "70vh",
    overflowY: "auto",
  },
}));

export interface ChatixMessage {
  actor: string;
  text: string;
  timestamp: string;
  sources?: { text: string; link: string; header: string }[];
}

export enum FeedbackState {
  Like,
  Dislike,
  Incomplete,
  Undefined,
}

interface Props {
  messages: ChatixMessage[];
  isReceivingAnswer: boolean;
  isConnected: boolean;
  onSubmitQuestion: (question: string) => void;
  onLikeHandler: (
    question: string,
    answer: string,
    like: FeedbackState
  ) => void;
}

const Chat = (props: Props) => {
  const classes = useStyles();
  const [question, setQuestion] = useState("");
  const [feedback, setFeedback] = useState<Array<FeedbackState>>([]);

  const handleQuestionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuestion(event.target.value);
  };

  const onLikeQuestion = (question: string, answer: string, index: number) => {
    if (props.messages.length > 1) {
      props.onLikeHandler(question, answer, FeedbackState.Like);
      const newFeedback = [...feedback];
      newFeedback[index] = FeedbackState.Like;
      setFeedback(newFeedback);
    }
  };

  const onDislikeQuestion = (
    question: string,
    answer: string,
    index: number
  ) => {
    if (props.messages.length > 1) {
      props.onLikeHandler(question, answer, FeedbackState.Dislike);
      const newFeedback = [...feedback];
      newFeedback[index] = FeedbackState.Dislike;
      setFeedback(newFeedback);
    }
  };

  const onIncompleteQuestion = (
    question: string,
    answer: string,
    index: number
  ) => {
    if (props.messages.length > 1) {
      props.onLikeHandler(question, answer, FeedbackState.Incomplete);
      const newFeedback = [...feedback];
      newFeedback[index] = FeedbackState.Incomplete;
      setFeedback(newFeedback);
    }
  };

  const onSubmitQuestion = () => {
    props.onSubmitQuestion(question);
    setQuestion("");
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      props.onSubmitQuestion(question);
      setQuestion("");
    }
  };

  const chatRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    //console.log(`Chat component mounted at ${new Date().toLocaleTimeString()}   ${chatRef.current?.scrollTop} end`);
    // Scroll to the bottom of the chat section when the component mounts
    // if (chatRef.current && chatRef.current.scrollTop){ - this doesn't work any more. ScollTop is always 0
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [props.messages, props.isReceivingAnswer]);

  return (
    <div style={{ width: "900px" }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" className="header-message">
            Dreamix.eu AI Assistant
          </Typography>
        </Grid>
      </Grid>
      <Grid container component={Paper} className={classes.chatSection}>
        <Grid item xs={12}>
          <List className={classes.messageArea} ref={chatRef}>
            <ListItem key="initial">
              <Grid container>
                <Grid item xs={12}>
                  <ListItemText
                    style={{ textAlign: "left" }}
                    primary="Hi! How can I help you today?"
                  ></ListItemText>
                </Grid>
                <Grid item xs={12}>
                  <ListItemText
                    style={{ textAlign: "left" }}
                    secondary={new Date().toLocaleString()}
                  ></ListItemText>
                </Grid>
              </Grid>
            </ListItem>
            {props.messages.map((message, index) => (
              <ListItem key={index}>
                <Grid container>
                  {/* <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}> */}
                  <Grid item xs={12}>
                    <ListItemText
                      style={
                        message.actor === "User"
                          ? {
                              textAlign: "right",
                              whiteSpace: "pre-wrap",
                              wordWrap: "break-word",
                            }
                          : {
                              textAlign: "left",
                              whiteSpace: "pre-wrap",
                              wordWrap: "break-word",
                            }
                      }
                      primary={message.text}
                    ></ListItemText>
                  </Grid>
                  <Grid item xs={12}>
                    <ListItemText
                      style={
                        message.actor === "User"
                          ? { textAlign: "right" }
                          : { textAlign: "left" }
                      }
                      secondary={message.timestamp}
                    ></ListItemText>
                  </Grid>
                  {/* </pre> */}

                  {message.actor === "Bot" && message.sources && (
                    <SourcesView sources={message.sources} />
                  )}

                  {message.actor === "Bot" && (
                    <Grid item xs={12}>
                      <IconButton
                        disabled={props.isReceivingAnswer}
                        aria-label="like"
                        onClick={() =>
                          onLikeQuestion(
                            props.messages[index - 1].text,
                            message.text,
                            index
                          )
                        }
                      >
                        <ThumbUpIcon
                          color={
                            feedback[index] === FeedbackState.Like
                              ? "primary"
                              : "inherit"
                          }
                        />{" "}
                        Good answer
                      </IconButton>
                      <IconButton
                        disabled={props.isReceivingAnswer}
                        aria-label="dislike"
                        onClick={() =>
                          onIncompleteQuestion(
                            props.messages[index - 1].text,
                            message.text,
                            index
                          )
                        }
                      >
                        <HelpIcon
                          color={
                            feedback[index] === FeedbackState.Incomplete
                              ? "primary"
                              : "inherit"
                          }
                        />{" "}
                        Incomplete answer
                      </IconButton>
                      <IconButton
                        disabled={props.isReceivingAnswer}
                        aria-label="dislike"
                        onClick={() =>
                          onDislikeQuestion(
                            props.messages[index - 1].text,
                            message.text,
                            index
                          )
                        }
                      >
                        <ThumbDownIcon
                          color={
                            feedback[index] === FeedbackState.Dislike
                              ? "primary"
                              : "inherit"
                          }
                        />{" "}
                        Wrong answer
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </ListItem>
            ))}
          </List>
          <Divider />
          <Grid container style={{ padding: "20px" }}>
            <Grid item xs={11}>
              <TextField
                id="outlined-basic-email"
                label="Enter your question here..."
                fullWidth
                value={question}
                onChange={handleQuestionChange}
                onKeyDown={handleKeyDown}
              />
            </Grid>
            <Grid xs={1} text-align="right">
              {!props.isReceivingAnswer && (
                <Fab
                  color="primary"
                  aria-label="add"
                  onClick={onSubmitQuestion}
                >
                  <SendIcon />
                </Fab>
              )}
              {props.isReceivingAnswer && <CircularProgress />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Chat;
