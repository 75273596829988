import React, { useState, useEffect } from "react";
import { io } from "socket.io-client";
import axios from "axios";

import "./App.css";
import Chat, { ChatixMessage, FeedbackState } from "./ChatComponent";

const SOCKET_URL = "http://127.0.0.1:1082";

const { REACT_APP_SOCKETIO_BASE_URL, REACT_APP_BACKEND_BASE_URL } = process.env;
//const  REACT_APP_BACKEND_BASE_URL  = 'http://127.0.0.1:1082';

const SOCKET_URL_ENV = "" + REACT_APP_SOCKETIO_BASE_URL;
//const SOCKET_URL_ENV="wss://doverie-demo.dreamix.eu"

export const socket = io(SOCKET_URL_ENV);

export const App = () => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const [messages, setMessages] = useState<Array<ChatixMessage>>([]);

  const [isConnected, setIsConnected] = useState(false);
  const [isReceivingAnswer, setIsReceivingAnswer] = useState(false);

  const [result, setResult] = useState("");

  const requestAutoDescription = () => {
    socket.emit("request_answer", { msg: "test" });
  };

  const handleChunkReceived = (value: string) => {
    setResult((result) => result + value);
    // replace last message in messages
    setMessages((messages) => [
      ...messages.slice(0, messages.length - 1),
      {
        actor: "Bot",
        text:
          messages.length > 0
            ? messages[messages.length - 1].text + value
            : value,
        timestamp: new Date().toISOString(),
        sources:
          messages.length > 0 ? messages[messages.length - 1].sources : [],
      },
    ]);
  };

  const hadleSourcesReceived = (sources: any) => {
    // replace last message in messages
    setMessages((messages) => [
      ...messages.slice(0, messages.length - 1),
      {
        actor: "Bot",
        text: messages.length > 0 ? messages[messages.length - 1].text : "",
        timestamp: new Date().toISOString(),
        sources: sources,
      },
    ]);
  };

  const hadleAnswerEndReceived = (value: string) => {
    setIsReceivingAnswer(false);
  };

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }
    if (socket.connected) {
      onConnect();
    }
    socket.on("answer_chunk", handleChunkReceived);
    socket.on("answer_ended", hadleAnswerEndReceived);
    socket.on("answer_sources", hadleSourcesReceived);
    socket.on("connect", onConnect);

    return () => {
      socket.off("answer_chunk", handleChunkReceived);
      socket.off("answer_ended", hadleAnswerEndReceived);
      socket.off("answer_sources", hadleSourcesReceived);
    };
  }, []);

  const handleSubmitQuestion = (question: string) => {
    const userMessage = {
      actor: "User",
      text: question,
      timestamp: new Date().toISOString(),
    };
    const botWaitMessage = {
      actor: "Bot",
      text: "",
      timestamp: new Date().toISOString(),
    };
    setMessages([...messages, userMessage, botWaitMessage]);

    const { REACT_APP_BACKEND_BASE_URL } = process.env;
    // axios.post(REACT_APP_BACKEND_BASE_URL+'/answer', { "question":question })
    // .then(response => {

    //   const botMessage = { actor: 'Bot',
    //                       text: response.data.response,
    //                       timestamp: new Date().toISOString(),
    //                       sources: response.data.sources
    //                     };
    //   setMessages([...messages, userMessage,  botMessage]);

    //   console.log(response.data);
    // })
    // .catch(error => {
    //   const botMessage = { actor: 'Bot',
    //   text: "Възникна грешка при търсене на информация. Моля, опитайте  по-късно.",
    //   timestamp: new Date().toISOString(),
    //   sources: []
    // };
    // setMessages([...messages, userMessage,  botMessage]);
    //       console.error(error);
    //     });
    setIsReceivingAnswer(true);
    socket.emit("request_answer", { msg: [...messages, userMessage] });
  };

  const handleFeedbackAction = (
    question: string,
    answer: string,
    feedback: FeedbackState
  ) => {
    axios
      .post(REACT_APP_BACKEND_BASE_URL + "/feedback", {
        question: question,
        answer: answer,
        feedback: feedback,
      })
      .then((response) => {})
      .catch((error) => {
        const botMessage = {
          actor: "Bot",
          text: "Възникна грешка при търсене на информация. Моля, опитайте  по-късно.",
          timestamp: new Date().toISOString(),
          sources: [],
        };
      });
  };

  return (
    <div className="App">
      <header className="App-header">
        <Chat
          messages={messages}
          onSubmitQuestion={handleSubmitQuestion}
          onLikeHandler={handleFeedbackAction}
          isReceivingAnswer={isReceivingAnswer}
          isConnected={isConnected}
        />
      </header>
    </div>
  );
};
